.jsoneditor input,
.jsoneditor input:not([type]),
.jsoneditor input[type=search],
.jsoneditor input[type=text],
.jsoneditor-modal input,
.jsoneditor-modal input:not([type]),
.jsoneditor-modal input[type=search],
.jsoneditor-modal input[type=text] {
    height: auto;
    border: inherit;
    box-shadow: none;
    font-size: inherit;
    box-sizing: inherit;
    padding: inherit;
    font-family: inherit;
    transition: none;
    line-height: inherit
}

.jsoneditor input:focus,
.jsoneditor input:not([type]):focus,
.jsoneditor input[type=search]:focus,
.jsoneditor input[type=text]:focus,
.jsoneditor-modal input:focus,
.jsoneditor-modal input:not([type]):focus,
.jsoneditor-modal input[type=search]:focus,
.jsoneditor-modal input[type=text]:focus {
    border: inherit;
    box-shadow: inherit
}

.jsoneditor textarea,
.jsoneditor-modal textarea {
    height: inherit
}

.jsoneditor select,
.jsoneditor-modal select {
    display: inherit;
    height: inherit
}

.jsoneditor label,
.jsoneditor-modal label {
    font-size: inherit;
    font-weight: inherit;
    color: inherit
}

.jsoneditor table,
.jsoneditor-modal table {
    border-collapse: collapse;
    width: auto
}

.jsoneditor td,
.jsoneditor th,
.jsoneditor-modal td,
.jsoneditor-modal th {
    padding: 0;
    display: table-cell;
    text-align: left;
    vertical-align: inherit;
    border-radius: inherit
}

.jsoneditor .autocomplete.dropdown {
    position: absolute;
    background: #fff;
    box-shadow: 2px 2px 12px rgba(128, 128, 128, .3);
    border: 1px solid #d3d3d3;
    overflow-x: hidden;
    overflow-y: auto;
    cursor: default;
    margin: 0;
    padding: 5px;
    text-align: left;
    outline: 0;
    font-family: "dejavu sans mono", "droid sans mono", consolas, monaco, "lucida console", "courier new", courier, monospace, sans-serif;
    font-size: 10pt
}

.jsoneditor .autocomplete.dropdown .item {
    color: #333
}

.jsoneditor .autocomplete.dropdown .item.hover {
    background-color: #ddd
}

.jsoneditor .autocomplete.hint {
    color: #aaa;
    top: 4px;
    left: 4px
}

.jsoneditor-contextmenu-root {
    position: relative;
    width: 0;
    height: 0
}

.jsoneditor-contextmenu {
    position: absolute;
    box-sizing: content-box;
    z-index: 1
}

.jsoneditor-contextmenu .jsoneditor-menu {
    position: relative;
    left: 0;
    top: 0;
    width: 128px;
    height: auto;
    background: #fff;
    border: 1px solid #d3d3d3;
    box-shadow: 2px 2px 12px rgba(128, 128, 128, .3);
    list-style: none;
    margin: 0;
    padding: 0
}

.jsoneditor-contextmenu .jsoneditor-menu button {
    position: relative;
    padding: 0 4px 0 0;
    margin: 0;
    width: 128px;
    height: auto;
    border: none;
    cursor: pointer;
    color: #4d4d4d;
    background: 0 0;
    font-size: 10pt;
    font-family: arial, sans-serif;
    box-sizing: border-box;
    text-align: left
}

.jsoneditor-contextmenu .jsoneditor-menu button::-moz-focus-inner {
    padding: 0;
    border: 0
}

.jsoneditor-contextmenu .jsoneditor-menu button.jsoneditor-default {
    width: 96px
}

.jsoneditor-contextmenu .jsoneditor-menu button.jsoneditor-expand {
    float: right;
    width: 32px;
    height: 24px;
    border-left: 1px solid #e5e5e5
}

.jsoneditor-contextmenu .jsoneditor-menu li {
    overflow: hidden
}

.jsoneditor-contextmenu .jsoneditor-menu li ul {
    display: none;
    position: relative;
    left: -10px;
    top: 0;
    border: none;
    box-shadow: inset 0 0 10px rgba(128, 128, 128, .5);
    padding: 0 10px;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    transition: all .3s ease-out
}

.jsoneditor-contextmenu .jsoneditor-menu li ul .jsoneditor-icon {
    margin-left: 24px
}

.jsoneditor-contextmenu .jsoneditor-menu li ul li button {
    padding-left: 24px;
    animation: all ease-in-out 1s
}

.jsoneditor-contextmenu .jsoneditor-menu li button .jsoneditor-expand {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0 4px 0 0;
    background: url(/assets/img/jsoneditor-icons.svg) 0 -72px
}

.jsoneditor-contextmenu .jsoneditor-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    border: none;
    padding: 0;
    margin: 0;
    background-image: url(/assets/img/jsoneditor-icons.svg)
}

.jsoneditor-contextmenu .jsoneditor-text {
    padding: 4px 0 4px 24px;
    word-wrap: break-word
}

.jsoneditor-contextmenu .jsoneditor-text.jsoneditor-right-margin {
    padding-right: 24px
}

.jsoneditor-contextmenu .jsoneditor-separator {
    height: 0;
    border-top: 1px solid #e5e5e5;
    padding-top: 5px;
    margin-top: 5px
}

.jsoneditor-contextmenu button.jsoneditor-remove .jsoneditor-icon {
    background-position: -24px 0
}

.jsoneditor-contextmenu button.jsoneditor-append .jsoneditor-icon {
    background-position: 0 0
}

.jsoneditor-contextmenu button.jsoneditor-insert .jsoneditor-icon {
    background-position: 0 0
}

.jsoneditor-contextmenu button.jsoneditor-duplicate .jsoneditor-icon {
    background-position: -48px 0
}

.jsoneditor-contextmenu button.jsoneditor-sort-asc .jsoneditor-icon {
    background-position: -168px 0
}

.jsoneditor-contextmenu button.jsoneditor-sort-desc .jsoneditor-icon {
    background-position: -192px 0
}

.jsoneditor-contextmenu button.jsoneditor-transform .jsoneditor-icon {
    background-position: -216px 0
}

.jsoneditor-contextmenu button.jsoneditor-extract .jsoneditor-icon {
    background-position: 0 -24px
}

.jsoneditor-contextmenu button.jsoneditor-type-string .jsoneditor-icon {
    background-position: -144px 0
}

.jsoneditor-contextmenu button.jsoneditor-type-auto .jsoneditor-icon {
    background-position: -120px 0
}

.jsoneditor-contextmenu button.jsoneditor-type-object .jsoneditor-icon {
    background-position: -72px 0
}

.jsoneditor-contextmenu button.jsoneditor-type-array .jsoneditor-icon {
    background-position: -96px 0
}

.jsoneditor-contextmenu button.jsoneditor-type-modes .jsoneditor-icon {
    background-image: none;
    width: 6px
}

.jsoneditor-contextmenu li,
.jsoneditor-contextmenu ul {
    box-sizing: content-box;
    position: relative
}

.jsoneditor-contextmenu .jsoneditor-menu button:focus,
.jsoneditor-contextmenu .jsoneditor-menu button:hover {
    color: #1a1a1a;
    background-color: #f5f5f5;
    outline: 0
}

.jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected,
.jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected:focus,
.jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected:hover {
    color: #fff;
    background-color: #ee422e
}

.jsoneditor-contextmenu .jsoneditor-menu li ul li button:focus,
.jsoneditor-contextmenu .jsoneditor-menu li ul li button:hover {
    background-color: #f5f5f5
}

.jsoneditor-modal {
    max-width: 95%;
    border-radius: 2px !important;
    padding: 45px 15px 15px 15px !important;
    box-shadow: 2px 2px 12px rgba(128, 128, 128, .3);
    color: #4d4d4d;
    line-height: 1.3em
}

.jsoneditor-modal.jsoneditor-modal-transform {
    width: 600px !important
}

.jsoneditor-modal .pico-modal-header {
    position: absolute;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    font-family: arial, sans-serif;
    font-size: 11pt;
    background: #3883fa;
    color: #fff
}

.jsoneditor-modal table {
    width: 100%
}

.jsoneditor-modal table td {
    padding: 3px 0
}

.jsoneditor-modal table td.jsoneditor-modal-input {
    text-align: right;
    padding-right: 0;
    white-space: nowrap
}

.jsoneditor-modal table td.jsoneditor-modal-actions {
    padding-top: 15px
}

.jsoneditor-modal table th {
    vertical-align: middle
}

.jsoneditor-modal p:first-child {
    margin-top: 0
}

.jsoneditor-modal a {
    color: #3883fa
}

.jsoneditor-modal .jsoneditor-jmespath-block {
    margin-bottom: 10px
}

.jsoneditor-modal .pico-close {
    background: 0 0 !important;
    font-size: 24px !important;
    top: 7px !important;
    right: 7px !important;
    color: #fff
}

.jsoneditor-modal input {
    padding: 4px
}

.jsoneditor-modal input[type=text] {
    cursor: inherit
}

.jsoneditor-modal input[disabled] {
    background: #d3d3d3;
    color: grey
}

.jsoneditor-modal .jsoneditor-select-wrapper {
    position: relative;
    display: inline-block
}

.jsoneditor-modal .jsoneditor-select-wrapper:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #666;
    position: absolute;
    right: 8px;
    top: 14px;
    pointer-events: none
}

.jsoneditor-modal select {
    padding: 3px 24px 3px 10px;
    min-width: 180px;
    max-width: 350px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-indent: 0;
    text-overflow: "";
    font-size: 10pt;
    line-height: 1.5em
}

.jsoneditor-modal select::-ms-expand {
    display: none
}

.jsoneditor-modal .jsoneditor-button-group input {
    padding: 4px 10px;
    margin: 0;
    border-radius: 0;
    border-left-style: none
}

.jsoneditor-modal .jsoneditor-button-group input.jsoneditor-button-first {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-left-style: solid
}

.jsoneditor-modal .jsoneditor-button-group input.jsoneditor-button-last {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px
}

.jsoneditor-modal .jsoneditor-transform-preview {
    background: #f5f5f5;
    height: 200px
}

.jsoneditor-modal .jsoneditor-transform-preview.jsoneditor-error {
    color: #ee422e
}

.jsoneditor-modal .jsoneditor-jmespath-wizard {
    line-height: 1.2em;
    width: 100%;
    padding: 0;
    border-radius: 3px
}

.jsoneditor-modal .jsoneditor-jmespath-label {
    font-weight: 700;
    color: #1e90ff;
    margin-top: 20px;
    margin-bottom: 5px
}

.jsoneditor-modal .jsoneditor-jmespath-wizard-table {
    width: 100%;
    border-collapse: collapse
}

.jsoneditor-modal .jsoneditor-jmespath-wizard-label {
    font-style: italic;
    margin: 4px 0 2px 0
}

.jsoneditor-modal .jsoneditor-inline {
    position: relative;
    display: inline-block;
    width: 100%;
    padding-top: 2px;
    padding-bottom: 2px
}

.jsoneditor-modal .jsoneditor-inline:not(:last-child) {
    padding-right: 2px
}

.jsoneditor-modal .jsoneditor-jmespath-filter {
    display: flex;
    flex-wrap: wrap
}

.jsoneditor-modal .jsoneditor-jmespath-filter-field {
    width: 180px
}

.jsoneditor-modal .jsoneditor-jmespath-filter-relation {
    width: 100px
}

.jsoneditor-modal .jsoneditor-jmespath-filter-value {
    min-width: 180px;
    flex: 1
}

.jsoneditor-modal .jsoneditor-jmespath-sort-field {
    width: 170px
}

.jsoneditor-modal .jsoneditor-jmespath-sort-order {
    width: 150px
}

.jsoneditor-modal .jsoneditor-jmespath-select-fields {
    width: 100%
}

.jsoneditor-modal .selectr-selected {
    border-color: #d3d3d3;
    padding: 4px 28px 4px 8px
}

.jsoneditor-modal .selectr-selected .selectr-tag {
    background-color: #3883fa;
    border-radius: 5px
}

.jsoneditor-modal table td,
.jsoneditor-modal table th {
    text-align: left;
    vertical-align: middle;
    font-weight: 400;
    color: #4d4d4d;
    border-spacing: 0;
    border-collapse: collapse
}

.jsoneditor-modal #query,
.jsoneditor-modal input,
.jsoneditor-modal input[type=text],
.jsoneditor-modal input[type=text]:focus,
.jsoneditor-modal select,
.jsoneditor-modal textarea {
    background: #fff;
    border: 1px solid #d3d3d3;
    color: #4d4d4d;
    border-radius: 3px;
    padding: 4px
}

.jsoneditor-modal,
.jsoneditor-modal #query,
.jsoneditor-modal input,
.jsoneditor-modal input[type=text],
.jsoneditor-modal option,
.jsoneditor-modal select,
.jsoneditor-modal table td,
.jsoneditor-modal table th,
.jsoneditor-modal textarea {
    font-size: 10.5pt;
    font-family: arial, sans-serif
}

.jsoneditor-modal #query,
.jsoneditor-modal .jsoneditor-transform-preview {
    font-family: "dejavu sans mono", "droid sans mono", consolas, monaco, "lucida console", "courier new", courier, monospace, sans-serif;
    font-size: 10pt;
    width: 100%;
    box-sizing: border-box
}

.jsoneditor-modal input[type=button],
.jsoneditor-modal input[type=submit] {
    background: #f5f5f5;
    padding: 4px 20px
}

.jsoneditor-modal input,
.jsoneditor-modal select {
    cursor: pointer
}

.jsoneditor-modal .jsoneditor-button-group.jsoneditor-button-group-value-asc input.jsoneditor-button-asc,
.jsoneditor-modal .jsoneditor-button-group.jsoneditor-button-group-value-desc input.jsoneditor-button-desc {
    background: #3883fa;
    border-color: #3883fa;
    color: #fff
}

.jsoneditor {
    color: #1a1a1a;
    border: thin solid #3883fa;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0;
    line-height: 100%
}

div.jsoneditor-default,
div.jsoneditor-field,
div.jsoneditor-readonly,
div.jsoneditor-value {
    border: 1px solid transparent;
    min-height: 16px;
    min-width: 32px;
    padding: 2px;
    margin: 1px;
    word-wrap: break-word;
    float: left
}

div.jsoneditor-field p,
div.jsoneditor-value p {
    margin: 0
}

div.jsoneditor-value {
    word-break: break-word
}

div.jsoneditor-value.jsoneditor-empty::after {
    content: "value"
}

div.jsoneditor-value.jsoneditor-string {
    color: #006000
}

div.jsoneditor-value.jsoneditor-number {
    color: #ee422e
}

div.jsoneditor-value.jsoneditor-boolean {
    color: #ff8c00
}

div.jsoneditor-value.jsoneditor-null {
    color: #004ed0
}

div.jsoneditor-value.jsoneditor-invalid {
    color: #000
}

div.jsoneditor-readonly {
    min-width: 16px;
    color: grey
}

div.jsoneditor-empty {
    border-color: #d3d3d3;
    border-style: dashed;
    border-radius: 2px
}

div.jsoneditor-field.jsoneditor-empty::after {
    content: "field"
}

div.jsoneditor td {
    vertical-align: top
}

div.jsoneditor td.jsoneditor-separator {
    padding: 3px 0;
    vertical-align: top;
    color: grey
}

div.jsoneditor td.jsoneditor-tree {
    vertical-align: top
}

div.jsoneditor.busy pre.jsoneditor-preview {
    background: #f5f5f5;
    color: grey
}

div.jsoneditor.busy div.jsoneditor-busy {
    display: inherit
}

div.jsoneditor code.jsoneditor-preview {
    background: 0 0
}

div.jsoneditor.jsoneditor-mode-preview pre.jsoneditor-preview {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    padding: 2px;
    margin: 0;
    white-space: pre-wrap;
    word-break: break-all
}

div.jsoneditor-default {
    color: grey;
    padding-left: 10px
}

div.jsoneditor-tree {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: auto
}

div.jsoneditor-tree button.jsoneditor-button {
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;
    background: transparent url(/assets/img/jsoneditor-icons.svg)
}

div.jsoneditor-tree button.jsoneditor-button:focus {
    background-color: #f5f5f5;
    outline: #e5e5e5 solid 1px
}

div.jsoneditor-tree button.jsoneditor-collapsed {
    background-position: 0 -48px
}

div.jsoneditor-tree button.jsoneditor-expanded {
    background-position: 0 -72px
}

div.jsoneditor-tree button.jsoneditor-contextmenu-button {
    background-position: -48px -72px
}

div.jsoneditor-tree button.jsoneditor-invisible {
    visibility: hidden;
    background: 0 0
}

div.jsoneditor-tree button.jsoneditor-dragarea {
    background: url(/assets/img/jsoneditor-icons.svg) -72px -72px;
    cursor: move
}

div.jsoneditor-tree :focus {
    outline: 0
}

div.jsoneditor-tree div.jsoneditor-show-more {
    display: inline-block;
    padding: 3px 4px;
    margin: 2px 0;
    background-color: #e5e5e5;
    border-radius: 3px;
    color: grey;
    font-family: arial, sans-serif;
    font-size: 10pt
}

div.jsoneditor-tree div.jsoneditor-show-more a {
    display: inline-block;
    color: grey
}

div.jsoneditor-tree div.jsoneditor-color {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 4px;
    border: 1px solid grey;
    cursor: pointer
}

div.jsoneditor-tree div.jsoneditor-date {
    background: #a1a1a1;
    color: #fff;
    font-family: arial, sans-serif;
    border-radius: 3px;
    display: inline-block;
    padding: 3px;
    margin: 0 3px
}

div.jsoneditor-tree table.jsoneditor-tree {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%
}

div.jsoneditor-tree .jsoneditor-button.jsoneditor-schema-error {
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0 4px 0 0;
    background: url(/assets/img/jsoneditor-icons.svg) -168px -48px
}

div.jsoneditor-outer {
    position: static;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

div.jsoneditor-outer.has-nav-bar {
    margin-top: -26px;
    padding-top: 26px
}

div.jsoneditor-outer.has-nav-bar.has-main-menu-bar {
    margin-top: -61px;
    padding-top: 61px
}

div.jsoneditor-outer.has-status-bar {
    margin-bottom: -26px;
    padding-bottom: 26px
}

div.jsoneditor-outer.has-main-menu-bar {
    margin-top: -35px;
    padding-top: 35px
}

div.jsoneditor-busy {
    position: absolute;
    top: 15%;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    display: none
}

div.jsoneditor-busy span {
    background-color: #ffffab;
    border: 1px solid #fe0;
    border-radius: 3px;
    padding: 5px 15px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .4)
}

div.jsoneditor-field.jsoneditor-empty::after,
div.jsoneditor-value.jsoneditor-empty::after {
    pointer-events: none;
    color: #d3d3d3;
    font-size: 8pt
}

a.jsoneditor-value.jsoneditor-url,
div.jsoneditor-value.jsoneditor-url {
    color: #006000;
    text-decoration: underline
}

a.jsoneditor-value.jsoneditor-url {
    display: inline-block;
    padding: 2px;
    margin: 2px
}

a.jsoneditor-value.jsoneditor-url:focus,
a.jsoneditor-value.jsoneditor-url:hover {
    color: #ee422e
}

div.jsoneditor-field.jsoneditor-highlight,
div.jsoneditor-field[contenteditable=true]:focus,
div.jsoneditor-field[contenteditable=true]:hover,
div.jsoneditor-value.jsoneditor-highlight,
div.jsoneditor-value[contenteditable=true]:focus,
div.jsoneditor-value[contenteditable=true]:hover {
    background-color: #ffffab;
    border: 1px solid #fe0;
    border-radius: 2px
}

div.jsoneditor-field.jsoneditor-highlight-active,
div.jsoneditor-field.jsoneditor-highlight-active:focus,
div.jsoneditor-field.jsoneditor-highlight-active:hover,
div.jsoneditor-value.jsoneditor-highlight-active,
div.jsoneditor-value.jsoneditor-highlight-active:focus,
div.jsoneditor-value.jsoneditor-highlight-active:hover {
    background-color: #fe0;
    border: 1px solid #ffc700;
    border-radius: 2px
}

div.jsoneditor-value.jsoneditor-array,
div.jsoneditor-value.jsoneditor-object {
    min-width: 16px
}

div.jsoneditor-tree button.jsoneditor-contextmenu-button.jsoneditor-selected,
div.jsoneditor-tree button.jsoneditor-contextmenu-button:focus,
div.jsoneditor-tree button.jsoneditor-contextmenu-button:hover,
tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-contextmenu-button {
    background-position: -48px -48px
}

div.jsoneditor-tree div.jsoneditor-show-more a:focus,
div.jsoneditor-tree div.jsoneditor-show-more a:hover {
    color: #ee422e
}

.ace-jsoneditor,
textarea.jsoneditor-text {
    min-height: 150px
}

.ace-jsoneditor *,
textarea.jsoneditor-text * {
    font-family: "dejavu sans mono", "droid sans mono", consolas, monaco, "lucida console", "courier new", courier, monospace, sans-serif
}

textarea.jsoneditor-text {
    width: 100%;
    height: 100%;
    margin: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline-width: 0;
    border: none;
    background-color: #fff;
    resize: none
}

tr.jsoneditor-highlight,
tr.jsoneditor-selected {
    background-color: #d3d3d3
}

tr.jsoneditor-selected button.jsoneditor-contextmenu-button,
tr.jsoneditor-selected button.jsoneditor-dragarea {
    visibility: hidden
}

tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-contextmenu-button,
tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-dragarea {
    visibility: visible
}

div.jsoneditor-tree button.jsoneditor-dragarea:focus,
div.jsoneditor-tree button.jsoneditor-dragarea:hover,
tr.jsoneditor-selected.jsoneditor-first button.jsoneditor-dragarea {
    background-position: -72px -48px
}

div.jsoneditor td,
div.jsoneditor th,
div.jsoneditor tr {
    padding: 0;
    margin: 0
}

.jsoneditor-popover,
.jsoneditor-schema-error,
div.jsoneditor td,
div.jsoneditor textarea,
div.jsoneditor th,
div.jsoneditor-field,
div.jsoneditor-value,
pre.jsoneditor-preview {
    font-family: "dejavu sans mono", "droid sans mono", consolas, monaco, "lucida console", "courier new", courier, monospace, sans-serif;
    font-size: 10pt;
    color: #1a1a1a
}

.jsoneditor-schema-error {
    cursor: default;
    display: inline-block;
    height: 24px;
    line-height: 24px;
    position: relative;
    text-align: center;
    width: 24px
}

.jsoneditor-popover {
    background-color: #4c4c4c;
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(0, 0, 0, .4);
    color: #fff;
    padding: 7px 10px;
    position: absolute;
    cursor: auto;
    width: 200px
}

.jsoneditor-popover.jsoneditor-above {
    bottom: 32px;
    left: -98px
}

.jsoneditor-popover.jsoneditor-above:before {
    border-top: 7px solid #4c4c4c;
    bottom: -7px
}

.jsoneditor-popover.jsoneditor-below {
    top: 32px;
    left: -98px
}

.jsoneditor-popover.jsoneditor-below:before {
    border-bottom: 7px solid #4c4c4c;
    top: -7px
}

.jsoneditor-popover.jsoneditor-left {
    top: -7px;
    right: 32px
}

.jsoneditor-popover.jsoneditor-left:before {
    border-left: 7px solid #4c4c4c;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    content: "";
    top: 19px;
    right: -14px;
    left: inherit;
    margin-left: inherit;
    margin-top: -7px;
    position: absolute
}

.jsoneditor-popover.jsoneditor-right {
    top: -7px;
    left: 32px
}

.jsoneditor-popover.jsoneditor-right:before {
    border-right: 7px solid #4c4c4c;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    content: "";
    top: 19px;
    left: -14px;
    margin-left: inherit;
    margin-top: -7px;
    position: absolute
}

.jsoneditor-popover:before {
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    content: "";
    display: block;
    left: 50%;
    margin-left: -7px;
    position: absolute
}

.jsoneditor-text-errors tr.jump-to-line:hover {
    text-decoration: underline;
    cursor: pointer
}

.jsoneditor-schema-error:focus .jsoneditor-popover,
.jsoneditor-schema-error:hover .jsoneditor-popover {
    display: block;
    animation: fade-in .3s linear 1, move-up .3s linear 1
}

@keyframes fade-in {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.jsoneditor .jsoneditor-validation-errors-container {
    max-height: 130px;
    overflow-y: auto
}

.jsoneditor .jsoneditor-validation-errors {
    width: 100%;
    overflow: hidden
}

.jsoneditor .jsoneditor-additional-errors {
    position: absolute;
    margin: auto;
    bottom: 31px;
    left: calc(50% - 92px);
    color: grey;
    background-color: #ebebeb;
    padding: 7px 15px;
    border-radius: 8px
}

.jsoneditor .jsoneditor-additional-errors.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 2s linear
}

.jsoneditor .jsoneditor-additional-errors.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 2s, opacity 2s linear
}

.jsoneditor .jsoneditor-text-errors {
    width: 100%;
    border-collapse: collapse;
    border-top: 1px solid #ffc700
}

.jsoneditor .jsoneditor-text-errors td {
    padding: 3px 6px;
    vertical-align: middle
}

.jsoneditor .jsoneditor-text-errors td pre {
    margin: 0;
    white-space: normal
}

.jsoneditor .jsoneditor-text-errors tr {
    background-color: #ffffab
}

.jsoneditor .jsoneditor-text-errors tr.parse-error {
    background-color: #ee2e2e70
}

.jsoneditor-text-errors .jsoneditor-schema-error {
    border: none;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0 4px 0 0;
    cursor: pointer
}

.jsoneditor-text-errors tr .jsoneditor-schema-error {
    background: url(/assets/img/jsoneditor-icons.svg) -168px -48px
}

.jsoneditor-text-errors tr.parse-error .jsoneditor-schema-error {
    background: url(/assets/img/jsoneditor-icons.svg) -25px 0
}

.jsoneditor-anchor {
    cursor: pointer
}

.jsoneditor-anchor .picker_wrapper.popup.popup_bottom {
    top: 28px;
    left: -10px
}

.fadein {
    -webkit-animation: fadein .3s;
    animation: fadein .3s;
    -moz-animation: fadein .3s;
    -o-animation: fadein .3s
}

@keyframes fadein {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

.jsoneditor-modal input[type=search].selectr-input {
    border: 1px solid #d3d3d3;
    width: calc(100% - 4px);
    margin: 2px;
    padding: 4px;
    box-sizing: border-box
}

.jsoneditor-modal button.selectr-input-clear {
    right: 8px
}

.jsoneditor-menu {
    width: 100%;
    height: 35px;
    padding: 2px;
    margin: 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff;
    background-color: #3883fa;
    border-bottom: 1px solid #3883fa
}

.jsoneditor-menu>.jsoneditor-modes>button,
.jsoneditor-menu>button {
    width: 26px;
    height: 26px;
    margin: 2px;
    padding: 0;
    border-radius: 2px;
    border: 1px solid transparent;
    background: transparent url(/assets/img/jsoneditor-icons.svg);
    color: #fff;
    opacity: .8;
    font-family: arial, sans-serif;
    font-size: 10pt;
    float: left
}

.jsoneditor-menu>.jsoneditor-modes>button:hover,
.jsoneditor-menu>button:hover {
    background-color: rgba(255, 255, 255, .2);
    border: 1px solid rgba(255, 255, 255, .4)
}

.jsoneditor-menu>.jsoneditor-modes>button:active,
.jsoneditor-menu>.jsoneditor-modes>button:focus,
.jsoneditor-menu>button:active,
.jsoneditor-menu>button:focus {
    background-color: rgba(255, 255, 255, .3)
}

.jsoneditor-menu>.jsoneditor-modes>button:disabled,
.jsoneditor-menu>button:disabled {
    opacity: .5;
    background-color: transparent;
    border: none
}

.jsoneditor-menu>button.jsoneditor-collapse-all {
    background-position: 0 -96px
}

.jsoneditor-menu>button.jsoneditor-expand-all {
    background-position: 0 -120px
}

.jsoneditor-menu>button.jsoneditor-sort {
    background-position: -120px -96px
}

.jsoneditor-menu>button.jsoneditor-transform {
    background-position: -144px -96px
}

.jsoneditor.jsoneditor-mode-form>.jsoneditor-menu>button.jsoneditor-sort,
.jsoneditor.jsoneditor-mode-form>.jsoneditor-menu>button.jsoneditor-transform,
.jsoneditor.jsoneditor-mode-view>.jsoneditor-menu>button.jsoneditor-sort,
.jsoneditor.jsoneditor-mode-view>.jsoneditor-menu>button.jsoneditor-transform {
    display: none
}

.jsoneditor-menu>button.jsoneditor-undo {
    background-position: -24px -96px
}

.jsoneditor-menu>button.jsoneditor-undo:disabled {
    background-position: -24px -120px
}

.jsoneditor-menu>button.jsoneditor-redo {
    background-position: -48px -96px
}

.jsoneditor-menu>button.jsoneditor-redo:disabled {
    background-position: -48px -120px
}

.jsoneditor-menu>button.jsoneditor-compact {
    background-position: -72px -96px
}

.jsoneditor-menu>button.jsoneditor-format {
    background-position: -72px -120px
}

.jsoneditor-menu>button.jsoneditor-repair {
    background-position: -96px -96px
}

.jsoneditor-menu>.jsoneditor-modes {
    display: inline-block;
    float: left
}

.jsoneditor-menu>.jsoneditor-modes>button {
    background-image: none;
    width: auto;
    padding-left: 6px;
    padding-right: 6px
}

.jsoneditor-menu>.jsoneditor-modes>button.jsoneditor-separator,
.jsoneditor-menu>button.jsoneditor-separator {
    margin-left: 10px
}

.jsoneditor-menu a {
    font-family: arial, sans-serif;
    font-size: 10pt;
    color: #fff;
    opacity: .8;
    vertical-align: middle
}

.jsoneditor-menu a:hover {
    opacity: 1
}

.jsoneditor-menu a.jsoneditor-poweredBy {
    font-size: 8pt;
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px
}

.jsoneditor-navigation-bar {
    width: 100%;
    height: 26px;
    line-height: 26px;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #d3d3d3;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: grey;
    background-color: #ebebeb;
    overflow: hidden;
    font-family: arial, sans-serif;
    font-size: 10pt
}

.jsoneditor-search {
    font-family: arial, sans-serif;
    position: absolute;
    right: 4px;
    top: 4px;
    border-collapse: collapse;
    border-spacing: 0;
    display: flex
}

.jsoneditor-search input {
    color: #1a1a1a;
    width: 120px;
    border: none;
    outline: 0;
    margin: 1px;
    line-height: 20px;
    font-family: arial, sans-serif
}

.jsoneditor-search button {
    width: 16px;
    height: 24px;
    padding: 0;
    margin: 0;
    border: none;
    background: url(/assets/img/jsoneditor-icons.svg);
    vertical-align: top
}

.jsoneditor-search button:hover {
    background-color: transparent
}

.jsoneditor-search button.jsoneditor-refresh {
    width: 18px;
    background-position: -99px -73px
}

.jsoneditor-search button.jsoneditor-next {
    cursor: pointer;
    background-position: -124px -73px
}

.jsoneditor-search button.jsoneditor-next:hover {
    background-position: -124px -49px
}

.jsoneditor-search button.jsoneditor-previous {
    cursor: pointer;
    background-position: -148px -73px;
    margin-right: 2px
}

.jsoneditor-search button.jsoneditor-previous:hover {
    background-position: -148px -49px
}

.jsoneditor-results {
    font-family: arial, sans-serif;
    color: #fff;
    padding-right: 5px;
    line-height: 26px
}

.jsoneditor-frame {
    border: 1px solid transparent;
    background-color: #fff;
    padding: 0 2px;
    margin: 0
}

.jsoneditor-statusbar {
    line-height: 26px;
    height: 26px;
    color: grey;
    background-color: #ebebeb;
    border-top: 1px solid #d3d3d3;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 10pt
}

.jsoneditor-statusbar>.jsoneditor-curserinfo-val {
    margin-right: 12px
}

.jsoneditor-statusbar>.jsoneditor-curserinfo-count {
    margin-left: 4px
}

.jsoneditor-statusbar>.jsoneditor-validation-error-icon {
    float: right;
    width: 24px;
    height: 24px;
    padding: 0;
    margin-top: 1px;
    background: url(/assets/img/jsoneditor-icons.svg) -168px -48px;
    cursor: pointer
}

.jsoneditor-statusbar>.jsoneditor-validation-error-count {
    float: right;
    margin: 0 4px 0 0;
    cursor: pointer
}

.jsoneditor-statusbar>.jsoneditor-parse-error-icon {
    float: right;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 1px;
    background: url(/assets/img/jsoneditor-icons.svg) -25px 0
}

.jsoneditor-statusbar .jsoneditor-array-info a {
    color: inherit
}

div.jsoneditor-statusbar>.jsoneditor-curserinfo-label,
div.jsoneditor-statusbar>.jsoneditor-size-info {
    margin: 0 4px
}

.jsoneditor-treepath {
    padding: 0 5px;
    overflow: hidden;
    white-space: nowrap;
    outline: 0
}

.jsoneditor-treepath.show-all {
    word-wrap: break-word;
    white-space: normal;
    position: absolute;
    background-color: #ebebeb;
    z-index: 1;
    box-shadow: 2px 2px 12px rgba(128, 128, 128, .3)
}

.jsoneditor-treepath.show-all span.jsoneditor-treepath-show-all-btn {
    display: none
}

.jsoneditor-treepath div.jsoneditor-contextmenu-root {
    position: absolute;
    left: 0
}

.jsoneditor-treepath .jsoneditor-treepath-show-all-btn {
    position: absolute;
    background-color: #ebebeb;
    left: 0;
    height: 20px;
    padding: 0 3px;
    cursor: pointer
}

.jsoneditor-treepath .jsoneditor-treepath-element {
    margin: 1px;
    font-family: arial, sans-serif;
    font-size: 10pt
}

.jsoneditor-treepath .jsoneditor-treepath-seperator {
    margin: 2px;
    font-size: 9pt;
    font-family: arial, sans-serif
}

.jsoneditor-treepath span.jsoneditor-treepath-element:hover,
.jsoneditor-treepath span.jsoneditor-treepath-seperator:hover {
    cursor: pointer;
    text-decoration: underline
}


.selectr-container {
    position: relative
}

.selectr-container li {
    list-style: none
}

.selectr-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0 none
}

.selectr-visible {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 11
}

.selectr-desktop.multiple .selectr-visible {
    display: none
}

.selectr-desktop.multiple.native-open .selectr-visible {
    top: 100%;
    min-height: 200px !important;
    height: auto;
    opacity: 1;
    display: block
}

.selectr-container.multiple.selectr-mobile .selectr-selected {
    z-index: 0
}

.selectr-selected {
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    width: 100%;
    padding: 7px 28px 7px 14px;
    cursor: pointer;
    border: 1px solid #999;
    border-radius: 3px;
    background-color: #fff
}

.selectr-selected::before {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 0;
    height: 0;
    content: '';
    -o-transform: rotate(0) translate3d(0, -50%, 0);
    -ms-transform: rotate(0) translate3d(0, -50%, 0);
    -moz-transform: rotate(0) translate3d(0, -50%, 0);
    -webkit-transform: rotate(0) translate3d(0, -50%, 0);
    transform: rotate(0) translate3d(0, -50%, 0);
    border-width: 4px 4px 0 4px;
    border-style: solid;
    border-color: #6c7a86 transparent transparent
}

.selectr-container.native-open .selectr-selected::before,
.selectr-container.open .selectr-selected::before {
    border-width: 0 4px 4px 4px;
    border-style: solid;
    border-color: transparent transparent #6c7a86
}

.selectr-label {
    display: none;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis
}

.selectr-placeholder {
    color: #6c7a86
}

.selectr-tags {
    margin: 0;
    padding: 0;
    white-space: normal
}

.has-selected .selectr-tags {
    margin: 0 0 -2px
}

.selectr-tag {
    list-style: none;
    position: relative;
    float: left;
    padding: 2px 25px 2px 8px;
    margin: 0 2px 2px 0;
    cursor: default;
    color: #fff;
    border: medium none;
    border-radius: 10px;
    background: #acb7bf none repeat scroll 0 0
}

.selectr-container.multiple.has-selected .selectr-selected {
    padding: 5px 28px 5px 5px
}

.selectr-options-container {
    position: absolute;
    z-index: 10000;
    top: calc(100% - 1px);
    left: 0;
    display: none;
    box-sizing: border-box;
    width: 100%;
    border-width: 0 1px 1px;
    border-style: solid;
    border-color: transparent #999 #999;
    border-radius: 0 0 3px 3px;
    background-color: #fff
}

.selectr-container.open .selectr-options-container {
    display: block
}

.selectr-input-container {
    position: relative;
    display: none
}

.selectr-clear,
.selectr-input-clear,
.selectr-tag-remove {
    position: absolute;
    top: 50%;
    right: 22px;
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer;
    -o-transform: translate3d(0, -50%, 0);
    -ms-transform: translate3d(0, -50%, 0);
    -moz-transform: translate3d(0, -50%, 0);
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
    border: medium none;
    background-color: transparent;
    z-index: 11
}

.selectr-clear,
.selectr-input-clear {
    display: none
}

.selectr-container.has-selected .selectr-clear,
.selectr-input-container.active .selectr-input-clear {
    display: block
}

.selectr-selected .selectr-tag-remove {
    right: 2px
}

.selectr-clear::after,
.selectr-clear::before,
.selectr-input-clear::after,
.selectr-input-clear::before,
.selectr-tag-remove::after,
.selectr-tag-remove::before {
    position: absolute;
    top: 5px;
    left: 9px;
    width: 2px;
    height: 10px;
    content: ' ';
    background-color: #6c7a86
}

.selectr-tag-remove::after,
.selectr-tag-remove::before {
    top: 4px;
    width: 3px;
    height: 12px;
    background-color: #fff
}

.selectr-clear:before,
.selectr-input-clear::before,
.selectr-tag-remove::before {
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg)
}

.selectr-clear:after,
.selectr-input-clear::after,
.selectr-tag-remove::after {
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.selectr-input-container.active,
.selectr-input-container.active .selectr-clear {
    display: block
}

.selectr-input {
    top: 5px;
    left: 5px;
    box-sizing: border-box;
    width: calc(100% - 30px);
    margin: 10px 15px;
    padding: 7px 30px 7px 9px;
    border: 1px solid #999;
    border-radius: 3px
}

.selectr-notice {
    display: none;
    box-sizing: border-box;
    width: 100%;
    padding: 8px 16px;
    border-top: 1px solid #999;
    border-radius: 0 0 3px 3px;
    background-color: #fff
}

.selectr-container.notice .selectr-notice {
    display: block
}

.selectr-container.notice .selectr-selected {
    border-radius: 3px 3px 0 0
}

.selectr-options {
    position: relative;
    top: calc(100% + 2px);
    display: none;
    overflow-x: auto;
    overflow-y: scroll;
    max-height: 200px;
    margin: 0;
    padding: 0
}

.selectr-container.notice .selectr-options-container,
.selectr-container.open .selectr-input-container,
.selectr-container.open .selectr-options {
    display: block
}

.selectr-option {
    position: relative;
    display: block;
    padding: 5px 20px;
    list-style: outside none none;
    cursor: pointer;
    font-weight: 400
}

.selectr-options.optgroups>.selectr-option {
    padding-left: 25px
}

.selectr-optgroup {
    font-weight: 700;
    padding: 0
}

.selectr-optgroup--label {
    font-weight: 700;
    margin-top: 10px;
    padding: 5px 15px
}

.selectr-match {
    text-decoration: underline
}

.selectr-option.selected {
    background-color: #ddd
}

.selectr-option.active {
    color: #fff;
    background-color: #5897fb
}

.selectr-option.disabled {
    opacity: .4
}

.selectr-option.excluded {
    display: none
}

.selectr-container.open .selectr-selected {
    border-color: #999 #999 transparent #999;
    border-radius: 3px 3px 0 0
}

.selectr-container.open .selectr-selected::after {
    -o-transform: rotate(180deg) translate3d(0, 50%, 0);
    -ms-transform: rotate(180deg) translate3d(0, 50%, 0);
    -moz-transform: rotate(180deg) translate3d(0, 50%, 0);
    -webkit-transform: rotate(180deg) translate3d(0, 50%, 0);
    transform: rotate(180deg) translate3d(0, 50%, 0)
}

.selectr-disabled {
    opacity: .6
}

.has-selected .selectr-placeholder,
.selectr-empty {
    display: none
}

.has-selected .selectr-label {
    display: block
}

.taggable .selectr-selected {
    padding: 4px 28px 4px 4px
}

.taggable .selectr-selected::after {
    display: table;
    content: " ";
    clear: both
}

.taggable .selectr-label {
    width: auto
}

.taggable .selectr-tags {
    float: left;
    display: block
}

.taggable .selectr-placeholder {
    display: none
}

.input-tag {
    float: left;
    min-width: 90px;
    width: auto
}

.selectr-tag-input {
    border: medium none;
    padding: 3px 10px;
    width: 100%;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit
}

.selectr-input-container.loading::after {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 20px;
    height: 20px;
    content: '';
    -o-transform: translate3d(0, -50%, 0);
    -ms-transform: translate3d(0, -50%, 0);
    -moz-transform: translate3d(0, -50%, 0);
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
    -o-transform-origin: 50% 0 0;
    -ms-transform-origin: 50% 0 0;
    -moz-transform-origin: 50% 0 0;
    -webkit-transform-origin: 50% 0 0;
    transform-origin: 50% 0 0;
    -moz-animation: .5s linear 0s normal forwards infinite running spin;
    -webkit-animation: .5s linear 0s normal forwards infinite running spin;
    animation: .5s linear 0s normal forwards infinite running spin;
    border-width: 3px;
    border-style: solid;
    border-color: #aaa #ddd #ddd;
    border-radius: 50%
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0) translate3d(0, -50%, 0);
        transform: rotate(0) translate3d(0, -50%, 0)
    }

    100% {
        -webkit-transform: rotate(360deg) translate3d(0, -50%, 0);
        transform: rotate(360deg) translate3d(0, -50%, 0)
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0) translate3d(0, -50%, 0);
        transform: rotate(0) translate3d(0, -50%, 0)
    }

    100% {
        -webkit-transform: rotate(360deg) translate3d(0, -50%, 0);
        transform: rotate(360deg) translate3d(0, -50%, 0)
    }
}

.selectr-container.open.inverted .selectr-selected {
    border-color: transparent #999 #999;
    border-radius: 0 0 3px 3px
}

.selectr-container.inverted .selectr-options-container {
    border-width: 1px 1px 0;
    border-color: #999 #999 transparent;
    border-radius: 3px 3px 0 0;
    background-color: #fff
}

.selectr-container.inverted .selectr-options-container {
    top: auto;
    bottom: calc(100% - 1px)
}

.selectr-container ::-webkit-input-placeholder {
    color: #6c7a86;
    opacity: 1
}

.selectr-container ::-moz-placeholder {
    color: #6c7a86;
    opacity: 1
}

.selectr-container :-ms-input-placeholder {
    color: #6c7a86;
    opacity: 1
}

.selectr-container ::placeholder {
    color: #6c7a86;
    opacity: 1
}