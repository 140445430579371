@import "../css/vars.scss";

// Begin title
.title {
    display: inline-block;
    text-align: left;

    i {
        vertical-align: middle;
        font-size: inherit;
    }
}

.wrapper-title {
    margin: $space-xl auto $space-xxl auto;
    width: $width-m;
    position: relative;
    line-height: 1;

    &.sub {
        margin: $space-xl auto;
    }

    h1 {
        margin: 0;
    }

    ul {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        li {
            display: inline-block;
            margin-left: $space-m;
        }
    }
}
@media (max-width: 1280px) {
    .wrapper-title {
        margin: 0 0 $space-l 0;
        width: 100%;
    }
}
@media (max-width: 720px) {
    .wrapper-title{
        ul{
            position: initial;
            transform: initial;
            margin-top: 32px;

            li {
                margin: 8px 0;
                display: block;
            }
        }
    }
}
// End title

.description {
    font-size: 12px;
    font-weight: 300;
    text-align: left;
    margin-top: $space-s;
}

// Begin buttons
%btn {
    height: 38px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    border-radius: 8px;
    padding: 0 $space-s;
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    transition: ease-out background 0.5s;

    &:disabled,
    &[disabled] {
        opacity: 0.4;
    }
    &:focus {
        outline: none;
    }
    span {
        padding: 0 $space-xs;
    }
    span, i {
        vertical-align: middle;
        line-height: 38px;
    }
}

.btn-base {
    @extend %btn;
}

.btn-master {
    @extend %btn;
    background: $primary-color;
    color: #fff;
    border: none;

    &:hover {
        background: $primary-dark-color;
    }
    span, i {
        color: #fff;
    }
}

.btn-primary {
    @extend %btn;
    color: #fff;
    background: $primary-color;
    border: none;

    &:hover {
        background: $primary-dark-color;
    }

    span, i {
        color: #fff;
    }
}

.btn-secondary {
    @extend %btn;
    color: $secondary-color;
    background: #fff;
    border: 1px solid $secondary-color;

    &:hover {
        color: #fff;
        background: $secondary-color;
        span, i {
            color: #fff;
        }
    }

    span, i {
        color: $secondary-color;
    }
}

.btn-secondary-invert {
    @extend %btn;
    color: #fff;
    background: $secondary-color;
    border: 1px solid $secondary-color;

    &:hover {
        color: $secondary-color;
        background: #fff;
        span, i {
            color: $secondary-color;
        }
    }

    span, i {
        color: $secondary-color;
    }
}

.btn-secondary-alternate {
    @extend %btn;
    color: $primary-color;
    background: #fff;
    border: 1px solid $primary-color;
    /*transition: ease-out border 0.5s;*/

    &:hover {
        background: #A1A1E6;
        border: 1px solid #A1A1E6;
    }

    span, i {
        color: $primary-color;
    }
}

.btn-text {
    @extend %btn;
    color: $primary-color;
    background: transparent;
    border: none;

    &:hover {
        background: #D9B8FF;
    }
    span, i {
        color: $primary-color;
    }
}
.btn-circle {
    width: 38px;
    height: 38px;
    border: solid 1px $secondary-color;
    border-radius: 100%;
    background-color: #fff;
    color: $secondary-color;
    vertical-align: middle;
    cursor: pointer;
    transition: color ease-out 0.5s, background-color ease-out 0.5s;

    &:hover {
        background-color: $secondary-color;
        color: #fff;
    }
    &:disabled,
    &[disabled] {
        opacity: 0.4;
    }
    &:focus {
        outline: none;
    }
}

.btn-icon {
    cursor: pointer;
    position: relative;
    display: inline-block;
    background: none;
    border: 0 none;
    width: 20px;
    height: 20px;
    margin-right: $space-xs;
    padding: 0;

    i {
        color: $primary-light-color;
        transition: ease-out color 0.5s;
    }

    &:hover {
        i {
            color: $secondary-light-color;
        }
    }
    &:disabled,
    &[disabled] {
        opacity: 0.4;
    }
    &:focus {
        outline: none;
    }

}

.btn-icon-dark {
    cursor: pointer;
    position: relative;
    display: block;
    background: none;
    border: 0 none;
    width: 38px;
    height: 38px;
    margin: 0;
    padding: 0;
    border-radius: 8px;
    transition: ease-out background-color 0.5s;

    i {
        font-size: 24px;
        line-height: 38px;
        color: $primary-color;
        transition: ease-out color 0.5s;
    }

    &:hover, &:active {
        background-color: #dec3ff;
    }

    &:disabled,
    &[disabled] {
        opacity: 0.4;
    }
    &:focus {
        outline: none;
    }
}
// End buttons

// Begin cards
.card-row {
    width: 100%;

    .card-container {
        width: auto;
        height: 320px;
        margin-bottom: $space-m;
        display: inline-block;
        vertical-align: top;

        .card-container-inner {
            margin-right: $space-m;
            width: 288px;
            height: 100%;
            display: inline-block;
            position: relative;
            cursor: pointer;
            border-radius: 6px;
            box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
            background-color: #fdfdfd;
            transition: background-color ease-out 0.5s;

            .card-container-inner-box{
                height: 100%;
                position: relative;
            }

            .image-card {
                background-image: url(/assets/img/customer.jpg);
                background-color: transparent;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                width: 85px;
                height: 85px;
                border-radius: 50%;
            }

            .title-card {
                text-align: left;
                font-weight: bold;
                font-size: 26px;
                display: block;
                line-height: 1;
                color: $text-color;
                display: inline-block;
                width: 100%;
            }

            .subtitle-card {
                font-size: 14px;
                text-align: left;
                font-weight: 300;
                display: block;
                margin: 0 0 $space-xs 0;
                color: $text-color;
                display: inline-block;
                width: 100%;
            }

            .box-link-card {
                display: block;
                width: 100%;
                height: 100%;
                text-decoration: none;
                color: $text-color;
                height: 242px;
                padding: $space-m $space-m 0 $space-m;
                position: relative;
                transition: background ease-out 0.5s;
                text-align: left;

                &:hover {
                    background: #c9a8ef;
                }

                figure {
                    width: 100%;
                    height: 190px;
                    margin: 0;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .info-card {
                position: absolute;
                bottom: $space-m;
                width: calc(100% - 48px);
            }

            .actions {
                width: 32px;
                height: 32px;
                background-size: 16px 16px;
                border: 0 none;
                display: inline-block;
                position: absolute;
                top: $space-xs;
                right: $space-xs;
                cursor: pointer;
                outline: none;
                background-color: #fff;
                border-radius: 50%;
                z-index: 3;
                color: $text-color;
                text-align: center;
                padding: 0;

                i {
                    vertical-align: middle;
                }

                &:hover,
                &.active {
                    background-color: #cfcfe2;
                }
            }

            .actions-buttons-bottom{
                border-top: 1px solid #929292;
                padding: 20px $space-m;
                text-align: right;

                button {
                    margin-left: $space-s;
                }
            }

            .actions-buttons {
                display: none;
                position: absolute;
                top: 50px;
                right: $space-xs;
                width: 216px;
                background: #fff;
                box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
                z-index: 3;

                li {
                    text-align: left;
                    a {
                        width: 100%;
                        height: 40px;
                        font-size: 12px;
                        line-height: 40px;
                        padding-left: $space-s;
                        display: block;
                        cursor: pointer;
                        position: relative;
                        color: $text-color;

                        &:hover {
                            background-color: #cfcfe2;
                        }

                        i{
                            position: absolute;
                            top: 50%;
                            right: 16px;
                            transform: translateY(-50%);
                            color: #000275;
                        }
                    }
                }
            }
        }
        &:nth-child(4n) .card-container-inner {
            margin-right: 0;
        }
    }
}
@media (max-width: 1280px) {
    .card-row{
        .card-container {
            width: calc(100% / 4);
            .card-container-inner {
                width: calc(100% - 16px);
                margin-right: 0;
            }
            &:nth-child(4n) .card-container-inner {
                margin-right: 0;
            }
        }
    }
}

@media (max-width: 1200px) and (min-width: 921px) {
    .card-row{
        .card-container {
            width: calc(100% / 3);
            .card-container-inner {
                width: calc(100% - 16px);
                margin-right: 0;
            }
            &:nth-child(3n) .card-container-inner {
                margin-right: 0;
            }
            &:nth-child(4n) .card-container-inner {
                margin-right: $space-m;
            }
        }
    }
}

@media (max-width: 920px) {
    .card-row{
        .card-container {
            width: calc(100% / 2);
            .card-container-inner {
                width: calc(100% - 16px);
                margin-right: 0;
            }
            &:nth-child(2n) .card-container-inner {
                margin-right: 0;
            }
            &:nth-child(3n) .card-container-inner {
                margin-right: $space-m;
            }
            &:nth-child(4n) .card-container-inner {
                margin-right: $space-m;
            }
        }
    }
}

@media (max-width: 720px) {
    .card-row{
        .card-container {
            width: 100%;

            .card-container-inner {
                width: 100%;
                margin: 0;
            }
        }
    }
}
// End cards

// Begin loader
.loading {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: rgba($color: #FFF, $alpha: 0.7);
    z-index: 9;

    img,
    svg {
        width: 100px;
        height: 100px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
// End loader

// Begin alerts
app-alert,
.alerts-rappid {
    position: fixed;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    width: 500px;
    z-index: 9;

    .alert {
        position: relative;
        box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
        border-radius: 4px;
        font-weight: 100;
        font-size: 14px;
        color: #fff;
        text-align: left;
        padding: 10px 35px 10px 10px;
        margin-bottom: 10px;

        animation-name: rightToLeft;
        animation-duration: 0.5s;

        &.alert-danger {
            background: #CC0000;
        }

        &.alert-success {
            background: #007E33;
        }

        &.alert-info {
            background: #0099CC;
        }

        &.alert-warning {
            background: #FF8800;
        }

        .alert-link {
            font-weight: bold;
            color: #fff;
        }

        .close {
            position: absolute;
            right: 0;
            font-size: 25px;
            top: 50%;
            transform: translateY(-50%);
            font-weight: 500;
            height: 40px;
            vertical-align: middle;
            display: inline-block;
            width: 40px;
            line-height: 40px;
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;
        }

    }
}

@keyframes rightToLeft {
    from {
        right: -100%;
    }

    to {
        right: 0;
    }
}
// End alerts

// Begin modal for rappid
.blocker {
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 73, 0.6);
    z-index: 2;
    top: 0;
    left: 0;
}

.modal2 {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    width: 40%;
    // max-width: 500px;
    max-height: 600px;
    box-sizing: border-box;
    background: #fff;
    padding: $space-xxl $space-xl $space-xl $space-xl;
    padding-top: 40px;
    box-sizing: border-box;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.35);
    border-radius: 6px;

    .close_modal {
        cursor: pointer;
        text-decoration: none;
        color: #333;
        font-weight: 400;
        padding: 0;
        position: absolute;
        right: 0px;
        top: 0px;
        display: inline-block;
        line-height: 1;
        width: 32px;
        height: 32px;
        vertical-align: middle;
        font-size: 32px;
        text-align: center;
        margin: $space-xs;
        background: transparent;
        border: 0 none;
    }

    .modal-title {
        margin: 0;
        font-size: 30px;
        font-weight: bold;
        text-align: center;
    }

    .modal-content {
        margin-top: $space-s;

        .content-copy {
            margin: $space-s 0 ;
            color: #5f5f5f;
            line-height: 38px;

            .btn-icon-dark {
                float: right;
            }
        }
    }

    .modal-actions {
        width: 100%;
        text-align: right;
        margin-top: $space-m;

        button {
            margin-left: $space-s;
        }
    }

    label {
        width: 100%;
        display: block;
    }

    .container-modal {
        box-sizing: border-box;
        padding: 10px 20px;
    }

    fieldset {
        margin: 0;
        padding: 0;
    }

    .label-check {
        display: inline-block;
        margin-bottom: 10px;
        width: calc(100% - 60px);
    }

    .label-radio {
        cursor: pointer;
        display: inline-block;
        margin-bottom: 10px;
        width: calc(100% - 60px);
    }

    input[type=checkbox] {
        display: block;
        float: right;
        padding-left: 10px;
        margin: 0;
    }

    textarea {
        height: auto;
    }
}
// End modal for rappid

// Begin tooltip for properties
.tooltip_rappid {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    text-align: left;
    cursor: help;

    img {
        width: 14px;
        height: 14px;
    }

    .left {
        width: 240px;
        top: 50%;
        right: 100%;
        margin-right: 7px;
        transform: translate(0, -50%);
        padding: 10px;
        color: #fff;
        background-color: #001430;
        font-weight: normal;
        font-size: 12px;
        border-radius: 4px;
        position: absolute;
        z-index: 99999999;
        box-sizing: border-box;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.5s;

        p {
            margin: 0;
            padding: 0;
        }

        i {
            position: absolute;
            top: 50%;
            left: 100%;
            margin-top: -11px;
            width: 11px;
            height: 22px;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                width: 11px;
                height: 11px;
                left: 0;
                top: 50%;
                transform: translate(-50%, -50%) rotate(-45deg);
                background-color: #001430;
                box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
            }
        }
    }

    &:hover .left {
        visibility: visible;
        opacity: 1;
    }
}
// End tooltip for properties

// Begin material icon sizes
.material-icons.md-16 {
    font-size: 16px;
}
.material-icons.md-20 {
    font-size: 20px;
}
// End material icon sizes

// Begin fields form
.field-box {
    margin-bottom: $space-m;
    position: relative;

    &.upload-box {
        &.excel {
            color: #009d60;
            border: dashed 1px #009d60;
            background-color: #e5f4eb;
            border-radius: 2px;
            padding: $space-xs;
            text-align: center;

            .files-list {
                .single-file {
                    border: solid 1px #009d60;
                }
            }
        }

        &.video {
            color: #333;
            border: dashed 1px #333;
            background-color: #fff;
            border-radius: 2px;
            padding: $space-xs;
            text-align: center;

            .files-list {
                .single-file {
                    border: solid 1px #333;
                }
            }
        }

        &.images {
            color: #333;
            border: dashed 1px #333;
            background-color: #fff;
            border-radius: 2px;
            padding: $space-xs;
            text-align: center;

            span {
                color: $secondary-dark-color;
            }
            .files-list {
                margin-top: 0;
                .single-file {
                    border: 0 none;
                    display: block;
                    img {
                        width: 64px;
                        height: 64px;
                        margin: 0 auto $space-s auto;
                    }
                    .file-delete {
                        position: absolute;
                        top: $space-xs;
                        margin: 0;
                        display: block;
                        right: $space-xs;
                    }
                    .file-name {
                        font-size: 12px;
                        text-align: center;
                        color: $secondary-light-color;
                    }
                }
            }
        }
        &.fileover {
            animation: shake 1s;
            animation-iteration-count: infinite;
        }
        &.dropped {
            border-color: $secondary-light-color;
            background-color: rgba(155, 76, 255, 0.1)
        }

        .files-list {
            margin-top: $space-s;

            .single-file {
                display: flex;
                flex-grow: 1;
                padding: $space-xs;
                justify-content: space-between;
                align-items: center;
                border: solid 1px #333;

                .file-icon {
                    display: flex;
                    margin-left: $space-xs;
                    margin-right: $space-s;
                    align-self: center;
                }

                .file-delete {
                    display: flex;
                    margin-left: $space-s;
                    margin-right: $space-xs;
                    cursor: pointer;
                    align-self: center;
                }

                .file-name {
                    font-size: 14px;
                    color: #333;
                    margin: 0;
                    text-align: left;
                }

                .file-size {
                    font-size: 12px;
                    color: #a4a4a4;
                    margin: 0;
                    text-align: left;
                }

                .file-info {
                    width: 100%;
                }
            }
          }

          /* Shake animation */
          @keyframes shake {
            0% {
              transform: translate(1px, 1px) rotate(0deg);
            }

            10% {
              transform: translate(-1px, -2px) rotate(-1deg);
            }

            20% {
              transform: translate(-3px, 0px) rotate(1deg);
            }

            30% {
              transform: translate(3px, 2px) rotate(0deg);
            }

            40% {
              transform: translate(1px, -1px) rotate(1deg);
            }

            50% {
              transform: translate(-1px, 2px) rotate(-1deg);
            }

            60% {
              transform: translate(-3px, 1px) rotate(0deg);
            }

            70% {
              transform: translate(3px, 1px) rotate(-1deg);
            }

            80% {
              transform: translate(-1px, -1px) rotate(1deg);
            }

            90% {
              transform: translate(1px, 2px) rotate(0deg);
            }

            100% {
              transform: translate(1px, -2px) rotate(-1deg);
            }
        }
    }

    figure {
        img {
            width: 40px;
            margin: 0 auto;
            display: block;
        }
    }

    i {
        font-size: 40px;
    }

    strong {
        font-size: 14px;
        display: block;
        width: 100%;
        text-align: center;
        font-weight: 800;
        margin: $space-xs 0;
        line-height: 1;
    }

    &.ckeditor {
        strong, i {
            font-size: inherit;
            display: inline;
            width: auto;
            text-align: left;
            font-weight: inherit;
            margin: 0;
            line-height: initial;
        }
        strong{
            font-weight: 800;
        }
    }

    span {
        font-size: 12px;
        display: block;
        width: 100%;
        text-align: center;
        line-height: 1;
    }

    button {
        display: inline;
        user-select: none;
        border: none;
        color: inherit;
        background: transparent;
        cursor: pointer;
        font-size: inherit;
        padding: 0;
        text-decoration: underline;
    }

    label {
        width: 100%;
        display: block;
        line-height: 1;
        margin: $space-xs 0;
        font-size: 14px;
        color: #333;
    }
    .description {
        font-size: 12px;
        text-align: left;
        margin-top: $space-s;
        color: #333;
    }
}

.field-compound {
    position: relative;

    input {
        padding-right: $space-xl;
    }

    button {
        position: absolute;
        top: 11px;
        right: 12px;

        i{
            font-size: 24px;
        }
    }
}
// End fields form

// Begin range
.range-wrap{
    width: 100%;
    position: relative;
}

.range-value{
    position: absolute;
    top: -35px;
    text-align: center;

    span {
        height: 24px;
        width: auto;
        line-height: 24px;
        text-align: center;
        background: $primary-light-color;
        color: #fff;
        font-size: 12px;
        display: block;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        border-radius: 6px;
        padding: 0 $space-xs;
        white-space: nowrap;
    }
    span:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-width: 5px 5px 0 5px;
        border-color: $primary-light-color transparent transparent transparent;
        border-style: solid;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        margin-top: -1px;
    }
}
.numeric {
    margin-bottom: 34px;
    position: relative;

    .bubble-range {
        background: $primary-disabled;
        color: white;
        padding: 4px 12px;
        position: absolute;
        border-radius: 4px;
        left: 50%;
        transform: translateX(-50%);

        &::after {
            content: "";
            position: absolute;
            width: 2px;
            height: 2px;
            background: $primary-disabled;
            top: -1px;
            left: 50%;
        }
    }
}
// End range

// Begin help button custom
.help {
    color: $secondary-color;
    vertical-align: middle;
    padding-left: 8px;
    cursor: help;

    &.white {
        color: white;
    }
}
// End help button custom

// Begin show/hide
.hide {
    display: none;
}
.show {
    display: block;
}
// End show/hide

// Begin animation fade
.fadeIn {
    animation-name: fadeIn;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    display: block !important;
}
.fadeOut {
    animation-name: fadeOut;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}
// End animation fade

// Begin ellipsis
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
// End ellipsis

// Begin breadcrumbs
.breadcrumbs-box {
    margin: $space-xl auto 0 auto;
    width: $width-m;
    position: relative;

    .breadcrumbs {
        li {
            display: inline-block;
            vertical-align: middle;

            i {
                font-size: 36px;
            }

            .btn-breadcrumb {
                margin: 0;
                padding: 0;
                border: none;
                background: transparent;
                color: #929292;
                height: 36px;
                font-size: 14px;
                font-weight: 600;
                cursor: pointer;
                display: inline-block;
                vertical-align: middle;

                &:focus {
                    outline: none;
                }
                span {
                    padding: 0 $space-xs;
                }

                span, i {
                    color: #929292;
                    vertical-align: middle;
                    line-height: 36px;
                }
                &:hover {
                    span, i {
                        text-decoration: underline;
                        color: $primary-color;
                        &:hover {
                            text-decoration: underline;
                            color: $primary-color;
                        }
                    }
                }
                &:disabled,
                &[disabled] {
                    cursor: not-allowed;
                    display: inline-block;
                    height: 36px;
                    line-height: 36px;
                    border-radius: 6px;
                    background: rgba(146, 146, 146, 0.12);
                    span, i {
                        color: #929292;
                        text-decoration: none;
                        &:hover {
                            text-decoration: none;
                            color: #929292;
                        }
                    }
                }
            }

            .separator-breadcrumb {
                display: inline-block;
                i {
                    display: inline-block;
                    vertical-align: middle;
                    color: #929292;
                }
            }
        }
    }

    &.inner-component{
        position: absolute;
        padding: 0;
        top: 88px;
        left: 320px;
        width: auto;
        z-index: 5;
        margin: 0;

        .breadcrumbs li .separator-breadcrumb i,
        .breadcrumbs li .btn-breadcrumb span,
        .breadcrumbs li .btn-breadcrumb i,
        .breadcrumbs li .btn-breadcrumb:disabled span,
        .breadcrumbs li .btn-breadcrumb:disabled i,
        .breadcrumbs li .btn-breadcrumb[disabled] span,
        .breadcrumbs li .btn-breadcrumb[disabled] i{
            color: #323232;
        }
    }
}

@media (max-width: 1280px) {
    .breadcrumbs-box {
        margin: 0;
        padding: $space-l $space-l 0 $space-l;
        width: 100%;
    }
}
// End breadcrumbs

// Begin chips
.chip {
    height: 38px;
    background-color: $secondary-dark-color;
    color: #fff;
    line-height: 38px;
    font-size: 14px;
    font-weight: 600;
    padding: 0 $space-s;
    margin: 0 $space-s $space-s 0;
    cursor: initial;

    i {
        font-size: 24px;
        position: relative;
        top: 4px;
    }
}
// End chips

// Begin tabs
.tabs {
    margin-bottom: $space-s;

    ul {
        li {
            display: inline-block;
            a {
                padding: $space-xs $space-s;
                cursor: pointer;
                display: block;
                font-size: 14px;
                line-height: 1.4;
                font-weight: 600;
                text-decoration: none;
                color: rgba(51, 51, 51, 0.7);
                border-bottom: 2px solid transparent;
                transition: ease-out background-color 0.5s, ease-in-out border-bottom 0.5s, ease-out color 0.5s;

                span {
                    font-size: 12px;
                    line-height: 1.4;
                    background-color: rgba(51, 51, 51, 0.4);
                    color: #fff;
                    border-radius: 8px;
                    object-fit: contain;
                    display: inline-block;
                    padding: 0 4px;
                    transition: ease-out background-color 0.5s;
                }

                &:hover {
                    color: rgb(51, 51, 51);

                    span {
                        background-color: rgb(51, 51, 51);
                    }
                }

                &.active {
                    background-color: rgba(155, 76, 255, 0.08);
                    border-bottom: 2px solid $primary-light-color;
                    color: $text-color;

                    span {
                        background-color: $primary-light-color;
                    }
                }
            }
        }
    }
}
// End tabs

// Begin table
.md-ui.component-data-table {
    background: #fff;
    border: 0;
    width: auto;

    .main-table-wrapper {
        display: block;
        max-width: 100%;
        overflow-x: auto;

        .main-table-content {
            border-collapse: collapse;
            border-spacing: 0;
            width: 100%;
            overflow: hidden;

            tr {
                border-bottom: 1px solid #e0e0e0;

                td {
                    text-overflow: ellipsis;

                    &.datatype-string {
                        text-align: left;
                    }
                    &:nth-child(6) {
                        width: 75px;
                    }
                    &:nth-child(8) {
                        width: 190px;
                    }
                }
            }

            .data-table-header {
                tr {
                    background-color: $primary-light-color;

                    td {
                        color: #fff;
                        font-size: 14px;
                        line-height: 1;
                        font-weight: 600;
                        vertical-align: middle;
                        padding: $space-s $space-xs;
                        padding: 0 8px;
                        height: 48px;
                    }
                }
            }

            .data-table-content {
                tr {
                    td {
                        line-height: 1;
                        color: $text-color;
                        font-weight: 400;
                        font-size: 14px;
                        padding: 0 8px;
                        height: 48px;

                        a {
                            cursor: pointer;
                        }

                        input[type="text"] {
                            background: transparent;
                            border: 0;
                            color: #212121;
                            font-family: 'Nunito Sans', sans-serif;
                            font-size: 13px;
                            font-weight: 400;
                            height: 40px;

                            &::placeholder {
                                color: rgba(0, 0, 0, .38);
                            }
                        }
                    }

                    &:nth-child(odd) {
                        background-color: rgba(196, 196, 196, 0.12);
                    }
                    &::nth-child(even) {
                        background-color: #fff;
                    }
                    &:hover {
                        background: #eee;
                    }
                }
            }
        }
    }

    .main-table-footer {
        color: rgba(0, 0, 0, .54);
        font-size: 15px;
        text-align: right;
        padding: 22px 0;
    }
}
// End table

// Begin pagination
.pagination {
    margin: 0;
    text-align: center;
    &:before,
    &:after {
        content: ' ';
        display: table;
    }
    &:after {
        clear: both;
    }
    li {
        user-select: none;
        margin: 0 3px;
        border-radius: 0;
        display: inline-block;
    }
    a, button{
        background: $primary-color;
        border: 1px solid $primary-color;
        border-radius: 8px;
        padding: $space-xs $space-s;
        color: #fff;
        display: block;
        cursor: pointer;

        &:hover {
            background: $primary-dark-color;
        }
    }
    .disabled,
    .current {
        background: #fff;
        border: 1px solid $primary-color;
        color: $primary-color;
        padding: $space-xs $space-s;
        border-radius: 8px;
        cursor: default;
    }
}
// End pagination

// Begin clearfix
.clearfix::after {
    content: "";
    clear: both;
    display: table;
}
// End clearfix
