ul.jqtree-tree {
    list-style: none outside;
    margin-left: 0;
    margin-bottom: 0;
    padding: 0;
}

    ul.jqtree-tree ul.jqtree_common {
        list-style: none outside;
        margin-left: 12px;
        margin-right: 0;
        margin-bottom: 0;
        padding: 0;
        display: block;
    }

    ul.jqtree-tree li.jqtree-closed > ul.jqtree_common {
        display: none;
    }

    ul.jqtree-tree li.jqtree_common {
        clear: both;
        list-style-type: none;
    }

    ul.jqtree-tree .jqtree-toggler {
        border-bottom: none;
        color: #333;
        text-decoration: none;
        vertical-align: middle;
    }

    ul.jqtree-tree .jqtree-toggler:hover {
            color: #000;
            text-decoration: none;
        }

    ul.jqtree-tree .jqtree-toggler.jqtree-closed {
            background-position: 0 0;
        }

    ul.jqtree-tree .jqtree-toggler.jqtree-toggler-left {
            margin-right: 0.5em;
        }

    ul.jqtree-tree .jqtree-toggler.jqtree-toggler-right {
            margin-left: 0.5em;
        }

    ul.jqtree-tree .jqtree-element {
        cursor: pointer;
        position: relative;
        display: flex;
    }

    ul.jqtree-tree .jqtree-title {
        color: #1C4257;
        vertical-align: middle;
        margin-left: 1.5em;
    }

    ul.jqtree-tree .jqtree-title.jqtree-title-folder {
            margin-left: 0;
        }

    ul.jqtree-tree li.jqtree-folder {
        margin-bottom: 4px;
    }

    ul.jqtree-tree li.jqtree-folder.jqtree-closed {
            margin-bottom: 1px;
        }

    ul.jqtree-tree li.jqtree-ghost {
        position: relative;
        z-index: 10;
        margin-right: 10px;
    }

    ul.jqtree-tree li.jqtree-ghost span {
            display: block;
        }

    ul.jqtree-tree li.jqtree-ghost span.jqtree-circle {
            border: solid 2px #0000ff;
            border-radius: 100px;
            height: 8px;
            width: 8px;
            position: absolute;
            top: -4px;
            left: -6px;
            box-sizing: border-box;
        }

    ul.jqtree-tree li.jqtree-ghost span.jqtree-line {
            background-color: #0000ff;
            height: 2px;
            padding: 0;
            position: absolute;
            top: -1px;
            left: 2px;
            width: 100%;
        }

    ul.jqtree-tree li.jqtree-ghost.jqtree-inside {
            margin-left: 48px;
        }

    ul.jqtree-tree span.jqtree-border {
        position: absolute;
        display: block;
        left: -2px;
        top: 0;
        border: solid 2px #0000ff;
        border-radius: 6px;
        margin: 0;
        box-sizing: content-box;
    }

    ul.jqtree-tree li.jqtree-selected > .jqtree-element,
    ul.jqtree-tree li.jqtree-selected > .jqtree-element:hover {
        /* background-color: #97BDD6; */
        /* background: linear-gradient(#BEE0F5, #89AFCA); */
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
    }

    ul.jqtree-tree .jqtree-moving > .jqtree-element .jqtree-title {
        outline: dashed 1px #0000ff;
    }

ul.jqtree-tree.jqtree-rtl {
    direction: rtl;
}

ul.jqtree-tree.jqtree-rtl ul.jqtree_common {
        margin-left: 0;
        margin-right: 12px;
    }

ul.jqtree-tree.jqtree-rtl .jqtree-toggler {
        margin-left: 0.5em;
        margin-right: 0;
    }

ul.jqtree-tree.jqtree-rtl .jqtree-title {
        margin-left: 0;
        margin-right: 1.5em;
    }

ul.jqtree-tree.jqtree-rtl .jqtree-title.jqtree-title-folder {
            margin-right: 0;
        }

ul.jqtree-tree.jqtree-rtl li.jqtree-ghost {
        margin-right: 0;
        margin-left: 10px;
    }

ul.jqtree-tree.jqtree-rtl li.jqtree-ghost span.jqtree-circle {
            right: -6px;
        }

ul.jqtree-tree.jqtree-rtl li.jqtree-ghost span.jqtree-line {
            right: 2px;
        }

ul.jqtree-tree.jqtree-rtl li.jqtree-ghost.jqtree-inside {
            margin-left: 0;
            margin-right: 48px;
        }

ul.jqtree-tree.jqtree-rtl span.jqtree-border {
        right: -2px;
    }

span.jqtree-dragging {
    color: #fff;
    background: #000;
    opacity: 0.6;
    cursor: pointer;
    padding: 2px 8px;
}
