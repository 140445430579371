/* colors */
$primary-color: #000275;
$primary-light-color: #492ca5;
$primary-dark-color: #000049;
$secondary-color: #5f10f2;
$secondary-light-color: #9b4cff;
$secondary-dark-color: #0000be;
$text-color: #333;
$success: #00c378;
$warning: #f6cc4f;
$warning2: #f07300;
$error: #e30000;
$danger: #bb2200;
$disabled: #d6d6d6;
$gray2: #929292;
$primary-disabled: rgba(0, 2, 118, .4);
$secondary-light-color2: #9b4cff;



/* Spacing */
$space-xs: 8px;
$space-s: 16px;
$space-m: 24px;
$space-l: 32px;
$space-xl: 48px;
$space-xxl: 64px;

/* heights */
$height-header: 58px;
$height-breadcrumb: 68px;
$height-section: calc(100% - #{$height-header} - #{$height-breadcrumb});

/* wrappers */
$width-l: 1440px;
$width-m: 1224px;

$width-fields: 344px;
